$(function(){

  var $body = $('body');
  var scrollTop;

  //スクロールを固定
  function bodyFixedOn() {
    scrollTop = $(window).scrollTop();

    $body.css({
      position: 'fixed',
      top: -scrollTop,
      'overflow-y': 'hidden'
    });
  }

  //スクロールの固定を解除
  function bodyFixedOff() {
    $body.css({
      position: '',
      top: '',
      'overflow-y': ''
    });
      
    $(window).scrollTop(scrollTop);
  }

  /////////////　ハンバーガーメニュー /////////////

  // 変数
  var humberger_icon = $('#js-open');
  var humberger_menu = $('#js-menu');
  var humberger_close = $('#js-close');

  // ハンバーガーメニュー　変数
  humberger_icon.click(function(){
    $(this).toggleClass('open');

    if (humberger_icon.hasClass('open')) {
      bodyFixedOn();
      humberger_menu.addClass('active');
      search_icon.removeClass('open');
      search_menu.removeClass('active');  
    } else {
      bodyFixedOff();
      humberger_menu.removeClass('active');
    }
  });
  humberger_close.click(function(){
    bodyFixedOff();
    humberger_icon.removeClass('open');
    humberger_menu.removeClass('active');
  });

	//現在のページURLのハッシュ部分を取得
	const hash = location.hash;

	//ハッシュ部分がある場合の条件分岐
	if(hash){
		//ページ遷移後のスクロール位置指定
		$("html, body").stop().scrollTop(0);
		//処理を遅らせる
		setTimeout(function(){
			//リンク先を取得
			const target = $(hash),
			//リンク先までの距離を取得
			position = target.offset().top;
			//指定の場所までスムーススクロール
			$("html, body").animate({scrollTop:position}, 500, "swing");
		},2000);
	}

  // pagetop
  var pagetop = $('#pagetop');

  pagetop.click(function () {
    $('body, html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });

  // ホッとStoryの最新リンク先取得
  function wpNewsStoryMenu() {
    var wp_url_story = '/wp-json/wp/v2/story/?_embed';
  
    $.ajax({
        type: 'GET',
        url: wp_url_story,
        dataType: 'json',
        data: {
            per_page: 1,
        }
    }).done(function(json){
  
        $.each(json, function( i, item ) {
            // URL
            var wp_report_link = item.link;
            const $header_link_class = $('.js-story-href-header');
            const $footer_link_class = $('.js-story-href-footer');
  
            //href属性の値を書き換える  
            $header_link_class.prop('href', wp_report_link);
            $footer_link_class.prop('href', wp_report_link);
        });
  
    }).fail(function(json){
        console.error('記事取得に失敗しました。')
    });
  }
  wpNewsStoryMenu();  

});